import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const PrivacyPolicy = () => {
  return (
    <Box sx={{ maxHeight: '70vh', overflowY: 'auto', padding: 2 }}>

      <Typography variant="h6" gutterBottom>
        1. Introducción
      </Typography>
      <Typography paragraph>
        Esta Política de Privacidad describe cómo Zicofy Software S.L. (en adelante, "ZicoAsis", "nosotros" o "la plataforma") recopila, utiliza y protege los datos personales de los usuarios (psicólogos y pacientes) que interactúan con la plataforma. Nos comprometemos a garantizar la protección de su privacidad y a cumplir con las normativas aplicables de protección de datos, incluyendo el Reglamento General de Protección de Datos (GDPR) y la normativa española vigente.
      </Typography>
      <Typography paragraph>
        Al utilizar ZicoAsis, usted acepta los términos de esta Política de Privacidad.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Responsable del Tratamiento de Datos
      </Typography>
      <Typography paragraph>
        El responsable del tratamiento de sus datos personales es:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Zicofy Software S.L.</li>
          <li>NIF: B19932045</li>
          <li>Domicilio: Av. de la República Argentina, 25, Planta 9, 41011, Sevilla, España.</li>
          <li>Contacto: ayuda@zicofy.com</li>
          <li>Data Compliance Officer: Agustín Rodríguez (NIE Z2188200P), contacto: agus@zicofy.com</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. Datos Personales que Recopilamos
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        3.1 Psicólogos
      </Typography>
      <Typography paragraph>
        Al registrarse en la plataforma, recopilamos los siguientes datos personales:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Datos de identificación: Nombre, apellidos, dirección de correo electrónico, teléfono, especialización y credenciales profesionales.</li>
          <li>Datos de interacción: Información sobre cómo utiliza la plataforma y su retroalimentación sobre el sistema.</li>
        </ul>
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        3.2 Pacientes
      </Typography>
      <Typography paragraph>
        Recopilamos los siguientes datos personales cuando los pacientes interactúan con ZicoAsis:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Datos de identificación: Nombre, edad, género, dirección de correo electrónico.</li>
          <li>Historial psicológico y médico: Datos relevantes para la evaluación y tratamiento del paciente.</li>
          <li>Datos de interacción: Interacciones con el asistente de inteligencia artificial (chats, sesiones, etc.).</li>
        </ul>
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        3.3 Uso de Cookies
      </Typography>
      <Typography paragraph>
        ZicoAsis utiliza cookies y tecnologías similares para mejorar la experiencia del usuario y realizar análisis de rendimiento. Las cookies que utilizamos incluyen:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Cookies necesarias: Para el correcto funcionamiento de la plataforma.</li>
          <li>Cookies de análisis y marketing: Utilizamos servicios de terceros como Google Analytics y HubSpot para entender el comportamiento del usuario y mejorar nuestros servicios. Los usuarios pueden gestionar sus preferencias de cookies a través de las configuraciones de su navegador.</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Finalidad del Tratamiento de Datos
      </Typography>
      <Typography paragraph>
        Utilizamos los datos personales con los siguientes fines:
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        4.1. Gestión de la Plataforma
      </Typography>
      <Typography component="div">
        <ul>
          <li>Creación y administración de cuentas de psicólogos y pacientes.</li>
          <li>Generación de informes detallados basados en las interacciones del paciente con la IA para el seguimiento del tratamiento.</li>
        </ul>
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        4.2. Mejora del Servicio
      </Typography>
      <Typography component="div">
        <ul>
          <li>Analizar el uso de la plataforma para mejorar la calidad y eficiencia de los servicios ofrecidos.</li>
          <li>Implementar mejoras técnicas y funcionales en base a la retroalimentación de los usuarios.</li>
        </ul>
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        4.3. Marketing
      </Typography>
      <Typography paragraph>
        Con su consentimiento, utilizamos los datos para enviarle comunicaciones promocionales a través de HubSpot, basadas en sus preferencias y comportamiento en la plataforma.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Base Jurídica para el Tratamiento de Datos
      </Typography>
      <Typography paragraph>
        El tratamiento de sus datos personales se basa en las siguientes bases jurídicas:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Ejecución de un contrato: La recopilación de datos es necesaria para ofrecer los servicios de ZicoAsis.</li>
          <li>Consentimiento: Los usuarios proporcionan su consentimiento explícito para el tratamiento de sus datos personales durante el registro.</li>
          <li>Cumplimiento de una obligación legal: En algunos casos, podemos estar obligados a procesar sus datos para cumplir con las leyes aplicables.</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. Con quién Compartimos sus Datos
      </Typography>
      <Typography paragraph>
        Sus datos personales podrán ser compartidos con:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Psicólogos registrados: Los informes generados para los pacientes son compartidos con sus respectivos psicólogos para facilitar el tratamiento y el seguimiento.</li>
          <li>Proveedores de servicios: Utilizamos servicios de terceros como Google Cloud y Firebase Firestore para el almacenamiento y procesamiento seguro de los datos.</li>
          <li>Plataformas de marketing: Para fines promocionales y de análisis, los datos pueden compartirse con HubSpot y Google Analytics.</li>
        </ul>
      </Typography>
      <Typography paragraph>
        Todos los proveedores de servicios externos cumplen con el GDPR y tienen implementadas medidas de seguridad adecuadas para proteger sus datos.
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. Transferencias Internacionales de Datos
      </Typography>
      <Typography paragraph>
        Dado que algunos de nuestros proveedores de servicios, como Google Cloud y HubSpot, están ubicados fuera del Espacio Económico Europeo (EEE), sus datos pueden transferirse a países que no ofrecen el mismo nivel de protección de datos que la legislación europea. Nos aseguramos de que estas transferencias estén cubiertas por las garantías adecuadas, como las Cláusulas Contractuales Tipo aprobadas por la Comisión Europea.
      </Typography>

      <Typography variant="h6" gutterBottom>
        8. Conservación de los Datos
      </Typography>
      <Typography paragraph>
        Conservaremos sus datos personales durante el tiempo que sea necesario para cumplir con los fines descritos en esta política, o mientras usted mantenga una cuenta activa en ZicoAsis. Una vez que los datos ya no sean necesarios, los eliminaremos de forma segura, a menos que tengamos la obligación legal de conservarlos.
      </Typography>

      <Typography variant="h6" gutterBottom>
        9. Sus Derechos
      </Typography>
      <Typography paragraph>
        De acuerdo con el GDPR, usted tiene los siguientes derechos en relación con sus datos personales:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Acceso: Puede solicitar una copia de los datos personales que tenemos sobre usted.</li>
          <li>Rectificación: Si sus datos son incorrectos o están incompletos, tiene derecho a solicitar su corrección.</li>
          <li>Supresión: Puede solicitar la eliminación de sus datos personales cuando ya no sean necesarios para los fines descritos.</li>
          <li>Oposición y limitación del tratamiento: Tiene derecho a oponerse al tratamiento de sus datos o solicitar que se limite el uso de los mismos.</li>
          <li>Portabilidad de los datos: Puede solicitar la transferencia de sus datos personales a otra entidad.</li>
        </ul>
      </Typography>
      <Typography paragraph>
        Para ejercer cualquiera de estos derechos, puede contactarnos en ayuda@zicofy.com. Responderemos a su solicitud en un plazo de 30 días.
      </Typography>

      <Typography variant="h6" gutterBottom>
        10. Seguridad de los Datos
      </Typography>
      <Typography paragraph>
        Nos comprometemos a proteger la seguridad de sus datos personales mediante:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Cifrado: Todos los datos son cifrados en tránsito y en reposo utilizando TLS.</li>
          <li>Controles de acceso: Solo el personal autorizado tiene acceso a los datos personales, y este acceso se controla estrictamente.</li>
          <li>Auditorías de seguridad: Realizamos auditorías periódicas para garantizar que los datos estén protegidos contra accesos no autorizados.</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        11. Modificaciones a esta Política
      </Typography>
      <Typography paragraph>
        ZicoAsis se reserva el derecho a modificar esta política de privacidad en cualquier momento. Cualquier cambio importante será comunicado a los usuarios con antelación a través de un aviso en la plataforma.
      </Typography>

      <Typography variant="h6" gutterBottom>
        12. Contacto
      </Typography>
      <Typography paragraph>
        Si tiene alguna pregunta o inquietud acerca de esta Política de Privacidad o sobre cómo manejamos sus datos personales, puede ponerse en contacto con nuestro Data Compliance Officer en:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Correo electrónico: ayuda@zicofy.com</li>
          <li>Dirección postal: Av. de la República Argentina, 25, Planta 9, 41011, Sevilla, España.</li>
        </ul>
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;