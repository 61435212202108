import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const AsesorMeeting = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: '1000px',
        height: '82vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        overflow: 'hidden'
      }}>
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <iframe
            src="https://zcal.co/i/V8hiKaZa"
            frameBorder="0"
            width="100%"
            height="100%"
            title="Discovery Call | ZicoAsis Demo - Agenda una reunión"
          ></iframe>
        </Box>
      </Box>
    </Modal>
  );
};

export default AsesorMeeting;