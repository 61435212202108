import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import FlashOnRoundedIcon from '@mui/icons-material/FlashOnRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';

const items = [
  {
    icon: <BarChartRoundedIcon />,
    title: 'Informes completos',
    description:
      'Accede a informes detallados y validados por profesionales expertos. Cada diagnóstico se basa en datos sólidos, ofreciendo recomendaciones personalizadas para planificar un tratamiento más preciso y efectivo.',
  },
  {
    icon: <AccessTimeRoundedIcon />,
    title: 'Eficiencia mejorada',
    description:
      'Optimiza cada consulta y ahorra tiempo gestionando tu agenda de manera más eficiente. Atiende a más pacientes manteniendo siempre una alta calidad en tu atención profesional.',
  },
  {
    icon: <SearchRoundedIcon />,
    title: 'Análisis profundo',
    description:
      'Nuestra IA identifica patrones y extrae insights clave desde la primera interacción, ofreciendo diagnósticos precisos y validados que te permiten tomar decisiones con total confianza.',
  },
  {
    icon: <FlashOnRoundedIcon />,
    title: 'Conexión inmediata',
    description:
      'Crea una relación de confianza desde el primer contacto. ZicoAsis utiliza técnicas avanzadas de conversación para captar las necesidades del paciente y fortalecer el vínculo terapéutico.',
  },
  {
    icon: <LockRoundedIcon />,
    title: 'Consulta personalizada',
    description:
      'Adapta las preguntas en tiempo real a las inquietudes del paciente. ZicoAsis facilita entrevistas flexibles que se ajustan a lo que realmente importa para ofrecer soluciones precisas.',
  },
  {
    icon: <FavoriteRoundedIcon />,
    title: 'Empatía y confidencialidad',
    description:
      'Proporciona una interacción empática y segura. Con un sistema que protege la privacidad, te enfocas en el bienestar emocional del paciente sin preocuparte por la seguridad de los datos.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Revolucionamos el diagnóstico 🔍🚀
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            ZicoAsis no solo agiliza el proceso, sino que te garantiza resultados más fiables y personalizados, avalados por un grupo de psicólogos expertos de diversas disciplinas. Esta validación asegura que cada informe sea preciso, específico y de alta calidad, permitiéndote brindar una atención más efectiva y centrada en cada paciente.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}