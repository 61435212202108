import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const logos = [
  'https://26887176.fs1.hubspotusercontent-eu1.net/hubfs/26887176/ZicoAsis.FilesAndMedia/Google_for_startups.png',
  'https://26887176.fs1.hubspotusercontent-eu1.net/hubfs/26887176/ZicoAsis.FilesAndMedia/USev_logo.png',
  'https://26887176.fs1.hubspotusercontent-eu1.net/hubfs/26887176/ZicoAsis.FilesAndMedia/Andalucia_logo.png',
  'https://26887176.fs1.hubspotusercontent-eu1.net/hubfs/26887176/ZicoAsis.FilesAndMedia/Telefonica_logo.png',
  'https://26887176.fs1.hubspotusercontent-eu1.net/hubfs/26887176/ZicoAsis.FilesAndMedia/Santander_logo.png',
  'https://26887176.fs1.hubspotusercontent-eu1.net/hubfs/26887176/ZicoAsis.FilesAndMedia/HubSpot_Startups.png',
  'https://26887176.fs1.hubspotusercontent-eu1.net/hubfs/26887176/ZicoAsis.FilesAndMedia/Lanzadera_logo.png',
];

const logoStyle = {
  width: '130px',
  height: '120px',
  margin: '0 20px',
  opacity: 0.7,
  objectFit: 'contain',
};

export default function LogoCollection() {
  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle1"
        align="center"
        sx={{ color: 'text.secondary' }}
      >
        Contamos con el <b>apoyo de líderes tecnológicos y científicos</b> en nuestra misión de potenciar el bienestar emocional
      </Typography>
      <Grid container sx={{ justifyContent: 'center', mt: 3, opacity: 0.6 }}>
        {logos.map((logo, index) => (
          <Grid item key={index}>
            <img
              src={logo}
              alt={`Logo de empresa colaboradora ${index + 1}`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}