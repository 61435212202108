import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const TermsConditions = () => {
  return (
    <Box sx={{ maxHeight: '70vh', overflowY: 'auto', padding: 2 }}>

      <Typography variant="h6" gutterBottom>
        1. Introducción
      </Typography>
      <Typography paragraph>
        Bienvenido a ZicoAsis, una plataforma desarrollada por Zicofy Software S.L. para proporcionar asistencia psicológica mediante inteligencia artificial. Al utilizar nuestros servicios, usted acepta cumplir con estos términos y condiciones. Si no está de acuerdo con alguno de los términos, le recomendamos no utilizar la plataforma.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Datos de la Empresa
      </Typography>
      <Typography paragraph>
        Responsable del Tratamiento de Datos:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Zicofy Software S.L.</li>
          <li>NIF: B19932045</li>
          <li>Dirección: Av. de la República Argentina, 25, Planta 9, 41011, Sevilla, España.</li>
          <li>Contacto: ayuda@zicofy.com</li>
        </ul>
      </Typography>
      <Typography paragraph>
        Data Compliance Officer:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Agustín Rodríguez (NIE Z2188200P)</li>
          <li>Correo: agus@zicofy.com</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. Objeto del Servicio
      </Typography>
      <Typography paragraph>
        ZicoAsis tiene como objetivo proporcionar a los psicólogos herramientas de inteligencia artificial para apoyar el diagnóstico y seguimiento de pacientes. La plataforma permite a los psicólogos:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Acceder a informes generados por IA basados en interacciones del paciente.</li>
          <li>Facilitar la interacción y seguimiento de sus pacientes mediante tecnología avanzada de chatbots e informes detallados.</li>
          <li>Utilizar datos obtenidos en sesiones interactivas para apoyar la práctica clínica.</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Uso de Datos Personales
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        4.1. Datos Recolectados:
      </Typography>
      <Typography paragraph>
        ZicoAsis recopila los siguientes datos personales:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Psicólogos: Identificadores personales, credenciales profesionales y datos de contacto.</li>
          <li>Pacientes: Datos identificativos, historial médico/psicológico y datos sobre interacciones con la plataforma.</li>
          <li>Cookies y seguimiento: ZicoAsis usa cookies para mejorar la experiencia del usuario y realizar acciones de marketing a través de HubSpot y Google Cloud.</li>
        </ul>
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        4.2. Finalidad del Tratamiento:
      </Typography>
      <Typography paragraph>
        Los datos serán utilizados para:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Facilitar la gestión de los perfiles de los psicólogos y sus pacientes.</li>
          <li>Generar informes detallados sobre las sesiones para el seguimiento clínico.</li>
          <li>Proporcionar funcionalidades de personalización y mejoras en la plataforma.</li>
        </ul>
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        4.3. Servicios de Terceros Utilizados:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Google Cloud y Firestore: Para almacenamiento de datos y gestión de la infraestructura.</li>
          <li>HubSpot: Para acciones de marketing y comunicación.</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Consentimiento del Usuario
      </Typography>
      <Typography paragraph>
        Los psicólogos aceptan que, al registrarse y utilizar la plataforma, los datos personales proporcionados tanto por ellos como por sus pacientes serán tratados de acuerdo con la legislación aplicable de protección de datos, incluidas las normativas del Reglamento General de Protección de Datos (GDPR) y la normativa española.
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. Derechos bajo el GDPR
      </Typography>
      <Typography paragraph>
        Los psicólogos y pacientes tienen derecho a:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Acceso: Solicitar información sobre los datos personales recopilados y tratados.</li>
          <li>Rectificación: Corregir cualquier error en los datos.</li>
          <li>Supresión: Solicitar la eliminación de sus datos cuando ya no sean necesarios para los fines descritos.</li>
          <li>Limitación y Oposición: Restringir el uso de sus datos o oponerse a su tratamiento.</li>
          <li>Portabilidad: Recibir los datos en un formato estructurado y de uso común.</li>
        </ul>
      </Typography>
      <Typography paragraph>
        Para ejercer cualquiera de estos derechos, los usuarios deben contactar a agus@zicofy.com.
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. Retiro del Consentimiento
      </Typography>
      <Typography paragraph>
        Los psicólogos pueden retirar su consentimiento para el tratamiento de datos en cualquier momento enviando una solicitud a ayuda@zicofy.com. Sin embargo, dicho retiro no afectará la legalidad del tratamiento basado en el consentimiento previo a su retirada.
      </Typography>

      <Typography variant="h6" gutterBottom>
        8. Cookies y Tratamiento de Datos
      </Typography>
      <Typography paragraph>
        ZicoAsis utiliza cookies para mejorar la experiencia de los usuarios, realizar análisis y ejecutar campañas de marketing. Las cookies empleadas incluyen aquellas proporcionadas por Google Analytics y HubSpot para el análisis del comportamiento del usuario y la gestión de campañas. Los usuarios pueden gestionar sus preferencias de cookies a través de su navegador.
      </Typography>

      <Typography variant="h6" gutterBottom>
        9. Medidas de Seguridad
      </Typography>
      <Typography paragraph>
        ZicoAsis emplea las siguientes medidas de seguridad para proteger los datos personales:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Cifrado: Todos los datos se cifran tanto en tránsito como en reposo, utilizando TLS y otros protocolos de seguridad.</li>
          <li>Control de Acceso: Solo el personal autorizado tiene acceso a los datos, y este acceso está limitado a la necesidad de conocer.</li>
          <li>Auditorías de Seguridad: Se realizan auditorías periódicas y monitoreo continuo de la infraestructura para detectar cualquier actividad sospechosa.</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        10. Responsabilidad
      </Typography>
      <Typography paragraph>
        ZicoAsis no será responsable por:
      </Typography>
      <Typography component="div">
        <ul>
          <li>Errores o interrupciones temporales del servicio.</li>
          <li>Uso indebido de la plataforma por parte de los psicólogos.</li>
          <li>Pérdidas de datos causadas por fallos externos al sistema o no imputables a ZicoAsis.</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        11. Modificaciones a los Términos
      </Typography>
      <Typography paragraph>
        ZicoAsis se reserva el derecho de modificar estos términos en cualquier momento. Los psicólogos serán notificados de cualquier cambio relevante con una antelación de 30 días antes de que entren en vigor.
      </Typography>

      <Typography variant="h6" gutterBottom>
        12. Contacto
      </Typography>
      <Typography paragraph>
        Para cualquier duda o consulta sobre estos términos, puede contactarnos a través de ayuda@zicofy.com.
      </Typography>
    </Box>
  );
};

export default TermsConditions;