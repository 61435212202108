import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import zicoasisLogo from '../assets/zicoasisLogo.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import PrivacyPolicy from './privacyPolicy';
import TermsConditions from './termsConditions';

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
      {'Copyright © '}
      ZicoAsis by Zicofy
      {' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

function PolicyDialog({ title, open, onClose, content }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
    </Dialog>
  );
}

export default function Footer() {
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const [openTerms, setOpenTerms] = React.useState(false);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        py: 4,
        textAlign: 'center',
      }}
    >
      <Box
        component="img"
        src={zicoasisLogo}
        alt="ZicoAsis Logo"
        sx={{ height: 50, mb: 2 }}
      />
      <Stack direction="row" spacing={2}>
        <Link
          color="text.secondary"
          variant="body2"
          onClick={() => setOpenPrivacy(true)}
          sx={{ cursor: 'pointer' }}
        >
          Política de Privacidad
        </Link>
        <Link
          color="text.secondary"
          variant="body2"
          onClick={() => setOpenTerms(true)}
          sx={{ cursor: 'pointer' }}
        >
          Términos de Servicio
        </Link>
      </Stack>
      <Copyright />
      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
        <IconButton
          color="inherit"
          size="small"
          href="https://www.linkedin.com/company/zicofy/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn"
        >
          <LinkedInIcon />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          href="https://www.instagram.com/zicofycom/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <InstagramIcon />
        </IconButton>
      </Stack>
      <PolicyDialog
        title="Política de privacidad"
        open={openPrivacy}
        onClose={() => setOpenPrivacy(false)}
        content={<PrivacyPolicy />}
      />
      <PolicyDialog
        title="Términos de servicio"
        open={openTerms}
        onClose={() => setOpenTerms(false)}
        content={<TermsConditions />}
      />
    </Container>
  );
}