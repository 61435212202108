import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AsesorMeeting from './asesorMeeting';

import { styled } from '@mui/material/styles';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: '6px solid',
  outlineColor: 'hsla(220, 25%, 80%, 0.2)',
  border: '1px solid',
  borderColor: theme.palette.grey[200],
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundImage: `url(${'/static/screenshots/material-ui/getting-started/templates/dashboard.jpg'})`,
  backgroundSize: 'cover',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    backgroundImage: `url(${'/static/screenshots/material-ui/getting-started/templates/dashboard-dark.jpg'})`,
    outlineColor: 'hsla(220, 20%, 42%, 0.1)',
    borderColor: theme.palette.grey[700],
  }),
}));

export default function Hero() {
  const [openAsesorMeeting, setOpenAsesorMeeting] = React.useState(false);

  const handleOpenAsesorMeeting = () => setOpenAsesorMeeting(true);
  const handleCloseAsesorMeeting = () => setOpenAsesorMeeting(false);

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={4}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              textAlign: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            IA para psicólogos: Diagnósticos iniciales más<span style={{ color: 'hsl(210, 98%, 48%)' }}> rápidos y precisos</span> 🚀
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
              fontSize: '1rem',
            }}
          >
            Descubre nuestra innovadora solución diseñada para acelerar y enriquecer los diagnósticos de tus pacientes. Ahorra tiempo y potencia tus consultas con la precisión de la inteligencia artificial.
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
              marginBottom: '0rem',
              fontSize: '1rem',
            }}
          >
            ¿Listo para revolucionar tus sesiones?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              pt: 0,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ minWidth: 'fit-content' }}
              onClick={handleOpenAsesorMeeting}
            >
              ¡Habla con un asesor ahora!
            </Button>
          </Box>
        </Stack>
        <StyledBox id="image">
          <img src="https://26887176.fs1.hubspotusercontent-eu1.net/hubfs/26887176/ZicoAsis.FilesAndMedia/HERO-FINAL-FINAL-FINAL.gif" alt="ZicoAsis en funcionamiento" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </StyledBox>
      </Container>
      <AsesorMeeting open={openAsesorMeeting} onClose={handleCloseAsesorMeeting} />
    </Box>
  );
}